import {
  TextLink,
  TextLinkProps,
  StyleObject,
} from '@modelberry/blue-lib/react'

const iconStyle: StyleObject = {
  color: 'var(--color-flame-400)',
}

export const RedTextLink = ({ anyCss, ...props }: TextLinkProps) => (
  <TextLink {...props} anyCss={[iconStyle, anyCss as any]} />
)
