import { gql } from '@apollo/client'
import { ContentfulPage } from '../../cms/contentful-page'

export type ContactFormVariant = 'contact'

/** @modelberry
 * - {@plugin "@modelberry/plugin-contentful/plain"}
 * - {@type contactForm}
 * - {@displayField title}
 * - {@description Contact form}
 * - {@name contactForm}
 */
export interface ContentfulContactForm {
  /** @modelberry {@ignore} */
  __typename?: string
  /** @modelberry {@ignore} */
  sys?: {
    id?: string
  }
  /** @modelberry
   * - {@name Titel binnen Contentful}
   * - {@required true}
   * - {@type Symbol}
   * - {@widgetId singleLine}
   * - {@helpText Alleen gebruikt in Contentful, wordt nooit getoond op de site zelf}
   */
  title?: string

  /** @modelberry
   * - {@name Sectie weergave}
   * - {@type Symbol}
   * - {@widgetId dropdown}
   * - {@helpText Hoe de sectie wordt weergegeven}
   * - {@validations in-contact-form-variant}
   */
  variant?: ContactFormVariant

  /** @modelberry
   * - {@name Label naamveld}
   * - {@type Text}
   * - {@widgetId singleLine}
   * - {@helpText Tekst voor het label bij het veld}
   */
  nameLabel?: string

  /** @modelberry
   * - {@name Placeholder naamveld}
   * - {@type Text}
   * - {@widgetId singleLine}
   * - {@helpText Tekst die als voorbeeld wordt getoond in het veld}
   */
  namePlaceholder?: string

  /** @modelberry
   * - {@name Naam is vereist}
   * - {@type Text}
   * - {@widgetId singleLine}
   * - {@helpText Tekst wordt getoond als het veld nog moet worden ingevuld}
   */
  nameRequired?: string

  /** @modelberry
   * - {@name Label e-mailveld}
   * - {@type Symbol}
   * - {@widgetId singleLine}
   * - {@helpText Tekst voor het label bij het veld}
   */
  emailLabel?: string

  /** @modelberry
   * - {@name Placeholder e-mailveld}
   * - {@type Text}
   * - {@widgetId singleLine}
   * - {@helpText Tekst die als voorbeeld wordt getoond in het veld}
   */
  emailPlaceholder?: string

  /** @modelberry
   * - {@name E-mailveld is vereist}
   * - {@type Text}
   * - {@widgetId singleLine}
   * - {@helpText Tekst wordt getoond als het veld nog moet worden ingevuld}
   */
  emailRequired?: string

  /** @modelberry
   * - {@name Label checkbox}
   * - {@type Symbol}
   * - {@widgetId singleLine}
   * - {@helpText Tekst als label bij het veld}
   */
  checkboxLabel?: string

  /** @modelberry
   * - {@name Checkbox is vereist}
   * - {@type Text}
   * - {@widgetId singleLine}
   * - {@helpText Tekst wordt getoond als het veld nog moet worden ingevuld}
   */
  checkboxRequired?: string

  /** @modelberry
   * - {@name Knop verturen}
   * - {@type Symbol}
   * - {@widgetId singleLine}
   * - {@helpText Tekst op de knop versturen}
   */
  sendButton?: string

  /** @modelberry
   * - {@name Verstuurd pagina}
   * - {@type Link}
   * - {@linkType Entry}
   * - {@widgetId entryLinkEditor}
   * - {@helpText Deze pagina wordt getoond nadat het formulier is vertuurd}
   * - {@validations linkContentType-page}
   */
  navigateToPage?: ContentfulPage

  /** @modelberry
   * - {@name Checkbox label link}
   * - {@type Link}
   * - {@linkType Entry}
   * - {@widgetId entryLinkEditor}
   * - {@helpText De link in het label van de checkbox verwijst naar deze pagina}
   * - {@validations linkContentType-page}
   */
  checkboxLabelLnkPage?: ContentfulPage
}

export const apolloContactFormFragment: any = gql`
  fragment Modelberry_ContactForm on ContactForm {
    __typename
    sys {
      id
    }
    variant
    nameLabel
    namePlaceholder
    nameRequired
    emailLabel
    emailPlaceholder
    emailRequired
    checkboxLabel
    checkboxRequired
    sendButton
    navigateToPage {
      path
    }
    checkboxLabelLnkPage {
      path
    }
  }
`
