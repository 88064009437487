import { Button, ButtonProps, StyleObject } from '@modelberry/blue-lib/react'

const buttonStyle: StyleObject = {
  backgroundColor: `var(--color-flame-400)`,
  border: '1px solid var(--color-flame-400)',
  ':active, :focus': {
    boxShadow: `0 0 6px var(--color-flame-300)`,
  },
  ':hover': {
    backgroundColor: `var(--color-flame-500)`,
    borderColor: `var(--color-flame-500)`,
  },
}

export const RedButton = ({ anyCss, ...props }: ButtonProps) => (
  <Button {...props} anyCss={[buttonStyle, anyCss as any]} />
)
