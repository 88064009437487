import { RedTextLink } from './text-links'
import { RedIcon } from './icons'
import { RedButton } from './buttons'
import { BlocksEmbeddedEntry } from './blocks-embedded-entry'
import { InlinesEntryHyperlink } from './inlines-entry-hyperlink'
import { RedDisplay } from './headings'
import { MdBody } from './body'

export const navigationSubComponents = {
  Button: RedButton,
  TextLink: RedTextLink,
}
export const richTextSubComponents = {
  BlocksEmbeddedEntry,
  Body: MdBody,
  Button: RedButton,
  Display: RedDisplay,
  // Heading: RedHeading,
  Icon: RedIcon,
  InlinesEntryHyperlink,
  TextLink: RedTextLink,
}
