import {
  Display,
  DisplayProps,
  Heading,
  HeadingProps,
  StyleObject,
} from '@modelberry/blue-lib/react'

const headingStyle: StyleObject = {
  color: 'var(--color-flame-400)',
}

export const RedDisplay = ({ anyCss, ...props }: DisplayProps) => (
  <Display {...props} anyCss={[headingStyle, anyCss as any]} />
)

export const RedHeading = ({ anyCss, ...props }: HeadingProps) => (
  <Heading {...props} anyCss={[headingStyle, anyCss as any]} />
)
